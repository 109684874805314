<script setup lang="ts">
import { useModalStore } from '@/modules/shared/stores/modal'
import ButtonG from '@/modules/shared/components/ButtonComponent.vue'
import { ref } from 'vue'

const store = useModalStore()
const finishRequest = ref(false)

interface Emits {
  (e: 'submit'):void;
}

const emits = defineEmits<Emits>()
const clicked = ref(false)

const props = defineProps({
  type: {
    type: String,
    default: 'Confirm'
  },
  color: {
    type: String,
    default: 'blue'
  },
  isDisabled: {
    type: Boolean,
    default: false
  }
})
</script>

<template>
  <div class="p-6 flex justify-between">
    <ButtonG type-color="bg-gray" text="Cancel" @onClick="store.hideModal" />

    <ButtonG
      :inactive="props.isDisabled || clicked"
      :type-color="props.color"
      :text="type"
      class="ml-2"
      :is-request="true"
      :finish-request="finishRequest"
      @onClick="emits('submit'); clicked = true;"
    />
  </div>
</template>

<style scoped></style>
