import { storeToRefs } from 'pinia'

import { useMailboxesStore } from '@/modules/settings/stores/mailboxes'
import { ref } from 'vue'
import type { Iitems } from '@/modules/shared/interfaces/inputMultiple'

const useInputMultiple = (props, emits) => {
  const count = ref(0)
  const inputAddEmail = ref()

  const storeMailboxes = useMailboxesStore()

  const { mailboxes } = storeToRefs(storeMailboxes)

  const removeEmpty = (text:string) => {
    if (!props.space) return text.split(/[\s,]+/).filter((str) => (typeof str === 'string' ? Boolean(str.trim()) : true))
    return [text]
  }

  const validEmail = (text:string) => {
    return removeEmpty(text).map((emailEmpty) => {
      const e = emailEmpty.trim()
      if (props.reg(e) || (props.onlyExistEmails && props.onlyExistEmails.includes(e)) || (props.allowedDomains.length > 0 && !props.allowedDomains.includes(e.split('@')[1]))) {
        return { item: e, status: false }
      }
      return {
        item: e,
        status: true,
        notTraking: !(mailboxes.value.filter((p:{ email: string }) => (p.email === e)).length > 0)
      }
    })
  }

  const placeholderF = () => {
    return !props.items.length ? props.placeholder : ''
  }
  const closeDropdown = (): void => {
    if (props.text.length > 0) insertItem()
  }

  const insert = (text:string) => {
    return validEmail(text)
  }

  const insertItem = () => {
    if (props.text.length > 0) {
      const a = [
        ...props.items,
        ...insert(props.text)
      ]
      emits('update:text', '')
      emits('update:items', (a as Iitems[]))
    }
  }

  const ChangeEmail = (e: Event) => {
    emits('update:text', (e.target as HTMLInputElement).value)
    count.value = 0
  }

  const DeleteEmail = (email:string) => {
    emits('update:items', [...props.items.filter((e: Iitems) => e.item !== email || e?.canNotDelete !== undefined)])
    emits('update:item', !props.item)
  }

  const DeleteLastEmail = () => {
    if (props.text.length === 0) count.value++
    if (count.value > 1) {
      if (props.items[props.items.length - 1]?.canNotDelete === undefined) {
        const a = props.items.filter((e:Iitems[]) => e !== props.items[props.items.length - 1])
        emits('update:items', [...a])
      }
    }
  }

  return {
    // variables
    inputAddEmail,

    // Methods
    insert,
    placeholderF,
    closeDropdown,
    insertItem,
    ChangeEmail,
    DeleteEmail,
    DeleteLastEmail
  }
}

export default useInputMultiple
