<script setup lang="ts">
import Spinner from './SpinnerComponent.vue'
import { computed, toRefs, ref, watch } from 'vue'
import { PlusIcon } from '@heroicons/vue/24/outline'

interface Emits {
  (e: 'on-click'): void;
  (e: 'refresh'): void;
}

const emits = defineEmits<Emits>()

const props = defineProps({
  text: {
    type: String,
    required: true
  },
  inactive: {
    type: Boolean,
    default: false
  },
  typeColor: {
    type: String,
    required: false,
    default: 'blue'
  },
  layout: {
    type: String,
    required: false,
    default: ''
  },
  loadingText: {
    type: String,
    default: ''
  },
  isRequest: {
    type: Boolean,
    default: false
  },
  finishRequest: {
    type: Boolean,
    default: false
  }
})
const { text, inactive, typeColor, layout, isRequest, finishRequest } = toRefs(props)
const loading = ref(false)

watch(
  () => finishRequest.value,
  (newVal) => {
    if (newVal) {
      if (inactive.value) loading.value = false
      else setTimeout(() => (loading.value = false), 2550)
      emits('refresh')
    }
  }
)

const Color = computed(() => {
  let buttonG
  let particularB

  switch (layout.value) {
    case 'ButtonRemove':
      buttonG = 'block px-4 py-2 font-normal text-sm'
      break
    case 'ButtonRemoveSelected':
      buttonG = 'inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-0 focus:ring-offset-0 disabled:cursor-not-allowed disabled:opacity-30'
      break
    default:
      buttonG = 'py-2.25 px-4 cursor-pointer text-sm font-medium rounded-md text-gray-700 focus:outline-none'
  }

  switch (typeColor.value) {
    case 'red':
      particularB = inactive.value ? '!cursor-not-allowed !bg-red-300 !text-white' : 'bg-red-600 hover:bg-red-700 text-white'
      break
    case 'bg-gray':
      particularB = inactive.value ? '!cursor-not-allowed text-gray-700' : 'bg-white border border-solid border-gray-300 !text-gray-800 hover:bg-gray-50'
      break
    case 'gray':
      particularB = inactive.value ? '!cursor-not-allowed text-gray-700' : 'bg-white text-gray-700'
      break
    case 'flat':
      particularB = inactive.value ? '!cursor-not-allowed' : 'text-indigo-500 border-0 !p-0 shadow-none cursor-pointer'
      break
    default:
      particularB = inactive.value || loading.value ? '!cursor-not-allowed bg-indigo-500 hover:bg-indigo-500 opacity-50 !text-white' : 'bg-indigo-500 text-white hover:bg-indigo-600'
  }

  return `${buttonG} ${particularB}`
})

const Icon = computed(() => ([
  'Add a mailbox',
  'Add a new user',
  'Add a new team',
  'Create group',
  'Create a new team',
  'Create a new alert',
  'Create my first alert'
].includes(text.value)))

const handleSpinnerColor = () => {
  if (props.typeColor === 'blue') return 'fill-blue-400'
  return 'fill-red-400'
}
</script>

<template>
  <button
    :class="Color"
    class="w-full hover:bg-gray-100 flex justify-center items-center"
    type="button"
    :disabled="inactive || loading"
    @click="() => { if(isRequest)  loading = true; emits('on-click'); }"
  >
    <div v-if="loading && isRequest" class="flex items-center gap-2 leading-9">
      <Spinner :class="`h-5 w-5 ${handleSpinnerColor()} text-gray-100`"></Spinner>
    </div>
    <PlusIcon v-if="Icon" class="text-white h-4 w-4 inline-block mr-2" aria-hidden="true" />
    <span v-if="!(loading && isRequest)">{{ text }}</span>
  </button>
</template>
