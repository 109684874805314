import { createRouter, createWebHistory } from 'vue-router'
import settingsRoutes from '@/modules/settings/settings_routes'
import exploreRoutes from '@/modules/explore/explore_routes'

import LoginComponent from '@/modules/shared/pages/LoginPage.vue'
import HomePage from '@/modules/shared/pages/HomePage.vue'
import NotFound from '@/modules/shared/pages/NotFound.vue'
import AccessDenied from '@/modules/shared/pages/AccessDenied.vue'

import { useUserInfoStore } from '@/modules/shared/stores/userInfo'
import clientsApi from '@/api/clients-api'
import { ME } from '@/api/url'

const routes = [
  {
    path: '/',
    redirect: {
      name: 'dashboard'
    }
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: HomePage
  },
  {
    path: '/login',
    name: 'login',
    component: LoginComponent,
    props: true
  },
  {
    path: '/access-denied',
    name: 'accessDenied',
    component: AccessDenied
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'notFound',
    component: NotFound
  }
]

routes.push(...settingsRoutes)
routes.push(...exploreRoutes)

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes
})

interface Feature {
  enabled: boolean;
}

let features: { [key: string]: Feature } = {}
let role: string

const handleBeforeRouteEnter = () => {
  return clientsApi.get(ME)
}

router.beforeEach(async (to, from, next) => {
  if (!Object.keys(features).length && (to.fullPath !== '/dashboard' && to.fullPath !== '/login')) {
    const store = useUserInfoStore()
    if (Object.keys(store.userInfo.features).length) {
      role = store.userInfo.roles
      features = store.userInfo.features as { [key: string]: Feature }
    } else {
      const { data } = await handleBeforeRouteEnter()
      role = data.role
      features = data.client_data.features as { [key: string]: Feature }
    }
  }
  if (to.fullPath !== '/dashboard' && to.fullPath !== '/login') {
    Object.keys(features).forEach((key) => {
      if ((to.fullPath.includes(key.substring(0, 6)) && !features[key].enabled) || role !== 'admin') next('notFound')
    })
  }
  next()
})

export default router
